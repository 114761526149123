<script setup lang="ts">
import type { AuthenticationType } from '@/types/auth';
import type { RouteRecordName } from 'vue-router';

defineProps({
	type: {
		type: Number as PropType<AuthenticationType>,
		default: AUTH_FORM.SIGN_IN,
	},
});

const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();

const redirectPage = ref();

watch(
	() => authStore.isLoggedIn,
	async (newValue) => {
		if (newValue && route.query?.redirect && route.query.redirect === ROUTE.EVENT_CREATE.NAME) {
			redirectPage.value = AUTH_PAGE_ORIGIN.CREATE;
		}
	},
);

onMounted(() => {
	if (authStore.isLoggedIn) {
		router.replace(ROUTE.INDEX.PATH);
	}
});
</script>
<template>
  <div class="signin-page-container">
    <AuthenticationContainerForm
      :type="type"
      :is-loading="authStore.isLoading"
      :page="redirectPage"
    />
  </div>
</template>
<style scoped lang="scss">
.signin-page-container {
	@include media-query-max(mobile) {
    padding: spacings-get(12) spacings-get(4) 0;
  }
}
</style>
<script setup lang="ts">
import type { ExtendedLocationDetail, LocationCoordinate } from '@/types/location';

const options = {
	fields: ['geometry', 'name', 'formatted_address'],
	strictBounds: false,
	types: ['(cities)'],
};

defineProps({
	disabled: {
		type: Boolean,
		default: false,
	},
	isTablet: {
		type: Boolean,
		default: false,
	},
	isTransparentBackground: {
		type: Boolean,
		default: false,
	},
});

const searchStore = useSearchStore();
const notificationStore = useNotificationStore();
const route = useRoute();
const router = useRouter();
const { geocoder, initGeocoder: initGoogleMapsGeocoder, fetchLocation } = useGoogleMaps();
const { getGeolocationPermission, handleGeolocationPromptResponse } = useGeoLocation();

const queryPayload = reactive({
	keyword: '',
	location: '',
	countryCode: '',
	coordinates: '',
});

const isFocusOnLocationInput = ref(false);
const isFocusOnKeywordInput = ref(false);
const isShowLocationMenu = ref(false);
const isGrantedGeolocationPermission = ref(false);
const currentCoordinate = ref<LocationCoordinate | null>(null);
const currentLocationName = ref('');
const isLoadingGeoLocation = ref(false);

function handleSavePayload() {
	// Briefly delay closing the location menu to allow user to select an option
	setTimeout(() => {
		if (!isFocusOnLocationInput.value) {
			isShowLocationMenu.value = false;
		}
	}, 200);
}

function initSearch() {
	// If location is empty (or becomes empty), reset the query payload to defaults.
	if (!queryPayload.location) {
		queryPayload.location = CITY_NAME.EVERYWHERE;
		queryPayload.coordinates = '';
		queryPayload.countryCode = '';
	}

	router.push({
		name: ROUTE.SEARCH.NAME,
		query: {
			...(queryPayload.keyword ? { [SEARCH_QUERY_NAME.KEYWORD]: queryPayload.keyword } : {}),
			...(queryPayload.coordinates ? { [SEARCH_QUERY_NAME.COORDINATES]: queryPayload.coordinates } : {}),
			...(queryPayload.countryCode ? { [SEARCH_QUERY_NAME.COUNTRY_CODE]: queryPayload.countryCode } : {}),
			...(queryPayload.location ? { [SEARCH_QUERY_NAME.LOCATION]: queryPayload.location } : {}),
			[SEARCH_QUERY_NAME.TIME_STAMP]: new Date().getTime(),
		},
	});
}

function handleLocationSelected(location: ExtendedLocationDetail) {
	queryPayload.location = location.address;
	queryPayload.countryCode = location.countryCode || '';
	queryPayload.coordinates = convertCoordinatesToString(location.coordinate);

	handleSavePayload();
	initSearch();
}

function handleBlur() {
	handleSavePayload();
	isFocusOnKeywordInput.value = false;
	isFocusOnLocationInput.value = false;
}

async function handlePressEnter() {
	// If a valid location is provided (not empty and not 'Everywhere'),
	// update queryPayload with fresh coordinates and country code.
	const isValidLocation = queryPayload.location !== '' && queryPayload.location !== CITY_NAME.EVERYWHERE;
	if (isValidLocation) {
		const { coordinates, countryCode } = await fetchLocation(queryPayload.location);

		if (coordinates) {
			queryPayload.coordinates = coordinates.toUrlValue();
			queryPayload.countryCode = countryCode;
		}
	}

	handleSavePayload();
	initSearch();
}

function handleFocusOnLocationInput() {
	isFocusOnLocationInput.value = true;

	if (!queryPayload.location) {
		isShowLocationMenu.value = true;
	}
}

function handleFocusOnKeywordInput() {
	isFocusOnKeywordInput.value = true;
}

async function initBrowserGeolocation() {
	if (!navigator.geolocation) {
		notificationStore.showWarningNotification('Geolocation is not supported by this browser.');
		return;
	}

	const permission = await getGeolocationPermission();

	if (permission.state === 'denied') {
		notificationStore.showWarningNotification('Please allow location permission in your browser settings.');
	} else if (permission.state === 'granted' || permission.state === 'prompt') {
		await handleGeolocationPromptResponse(
			(position) => {
				currentCoordinate.value = {
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				};
				isGrantedGeolocationPermission.value = true;
			},
			() => {
				notificationStore.showWarningNotification('Please allow location permission in your browser settings.');
			},
		);
	}
}

async function initializeGeolocation() {
	try {
		await initBrowserGeolocation();
		return true;
	} catch {
		isLoadingGeoLocation.value = false;
		return false;
	}
}

async function initializeGeocoder() {
	try {
		await initGoogleMapsGeocoder();
		return true;
	} catch {
		isLoadingGeoLocation.value = false;
		return false;
	}
}

async function getLocationData() {
	try {
		const { cityName, countryName, countryCode } = await fetchLocation('', currentCoordinate.value);
		return { cityName, countryName, countryCode, coordinatesValue: currentCoordinate.value };
	} catch {
		isLoadingGeoLocation.value = false;
		return null;
	}
}

function updateLocationStore({
	cityName,
	countryName,
	countryCode,
	coordinatesValue,
}: {
	cityName: string;
	countryName: string;
	countryCode: string;
	coordinatesValue: LocationCoordinate | null;
}) {
	currentLocationName.value = cityName ? `${cityName}, ${countryName}` : `${countryName}`;
	searchStore.currentLocation = {
		city: cityName,
		country: countryName,
		countryCode,
		coordinates: coordinatesValue,
		name: currentLocationName.value,
	};
}

function updateSearchPayload({
	countryCode,
	coordinatesValue,
}: {
	countryCode: string;
	coordinatesValue: LocationCoordinate | null;
}) {
	queryPayload.location = currentLocationName.value;
	queryPayload.countryCode = countryCode;
	queryPayload.coordinates = convertCoordinatesToString(coordinatesValue);
}

async function handleSelectCurrentLocation() {
	isLoadingGeoLocation.value = true;

	// If current location has been stored in the current session
	// No need to check for geolocation data, or call Google Maps API
	// Otherwise, request user's current location
	if (searchStore.currentLocation.countryCode) {
		// Retrieve current location from store
		queryPayload.coordinates = convertCoordinatesToString(searchStore.currentLocation.coordinates);
		queryPayload.countryCode = searchStore.currentLocation.countryCode;
		queryPayload.location = searchStore.currentLocation.name;
	} else {
		// Request user's current location if geolocation permission has not been granted
		if (!isGrantedGeolocationPermission.value) {
			if (!(await initializeGeolocation())) {
				return;
			}
		}

		// Initialize Google Maps Geocoder if it has not been initialized yet
		if (!geocoder.value) {
			if (!(await initializeGeocoder())) {
				return;
			}
		}

		const locationData = await getLocationData();
		if (!locationData) {
			return;
		}

		updateLocationStore(locationData);
		updateSearchPayload(locationData);
	}

	isLoadingGeoLocation.value = false;
	handleSavePayload();
	initSearch();
}

function handleSelectEverywhere() {
	handleSavePayload();
	initSearch();
}

function handleLocationChange(value: string) {
	queryPayload.location = value;
}

function handleKeywordChange(value: string) {
	queryPayload.keyword = value;
}

watch(() => route.query, (newQuery) => {
	if (newQuery) {
		queryPayload.keyword = String(newQuery?.[SEARCH_QUERY_NAME.KEYWORD] || '');
		queryPayload.location = String(newQuery?.[SEARCH_QUERY_NAME.LOCATION] || '');
		queryPayload.countryCode = String(newQuery?.[SEARCH_QUERY_NAME.COUNTRY_CODE] || '');
		queryPayload.coordinates = String(newQuery?.[SEARCH_QUERY_NAME.COORDINATES] || '');
	}
});

onMounted(async () => {
	if (window.google && window.google.maps) {
		await initGoogleMapsGeocoder();
	} else {
		window.addEventListener('load', async () => {
			if (window.google && window.google.maps) {
				await initGoogleMapsGeocoder();
			}
		});
	}
});
</script>

<template>
  <div>
    <!-- Search box in tablet screen -->
    <SearchMenu
      v-if="isTablet"
      :location-input="queryPayload.location"
      :keyword-input="queryPayload.keyword"
      :is-transparent-background="isTransparentBackground"
      :is-show-location-menu="isShowLocationMenu"
      :options="options"
      @on-location-input-change="handleLocationChange"
      @on-keyword-input-change="handleKeywordChange"
      @on-select-location="handleLocationSelected"
      @on-focus-location="handleFocusOnLocationInput"
      @on-focus-keyword="handleFocusOnKeywordInput"
      @on-select-current-location="handleSelectCurrentLocation"
      @on-select-everywhere="handleSelectEverywhere"
      @blur="handleBlur"
      @enter="handlePressEnter"
    />
    <!-- End Search box in tablet screen -->

    <!-- Search box in desktop screen -->
    <template v-else>
      <div
        :class="[
          'search-box-container',
          {
            '--focus': isFocusOnKeywordInput || isFocusOnLocationInput,
            '--disabled': disabled,
          }
        ]"
      >
        <div
          :class="['keyword-search-input', {
            '--disabled': disabled || isLoadingGeoLocation
          }]"
        >
          <SearchIcon
            class="icon"
            width="20"
            height="20"
            color="#667085"
          />
          <input
            ref="inputElement"
            v-model="queryPayload.keyword"
            placeholder="Search events"
            class="text-field"
            :disabled="disabled || isLoadingGeoLocation"
            @focus="handleFocusOnKeywordInput"
            @blur="handleBlur"
            @keydown.enter="handlePressEnter"
          >
        </div>

        <div class="location-search-input">
          <div class="input-wrapper">
            <div class="separator" />
            <BaseGoogleAutoCompleteForSearch
              id="menu-activator"
              v-model="queryPayload.location"
              placeholder="Search by city"
              :options="options"
              :disabled="disabled || isLoadingGeoLocation"
              is-search-bar
              @on-select-location="handleLocationSelected"
              @focus="handleFocusOnLocationInput"
              @blur="handleBlur"
              @enter="handlePressEnter"
            >
              <template #icon>
                <MarkerPinIcon
                  class="icon"
                  color="#667085"
                />
              </template>
            </BaseGoogleAutoCompleteForSearch>

            <VList
              v-if="isShowLocationMenu"
              class="location-menu"
            >
              <VListItem
                min-height="36"
                class="text-regular"
                :ripple="false"
                @click="handleSelectCurrentLocation"
              >
                <button class="location-menu-item">
                  <MarkerPinAltIcon color="#475467" />
                  <p>Use my current location</p>
                </button>
              </VListItem>
              <VListItem
                min-height="36"
                class="text-regular"
                :ripple="false"
                @click="handleSelectEverywhere"
              >
                <button class="location-menu-item">
                  <GlobePinIcon color="#475467" />
                  <p>Everywhere</p>
                </button>
              </VListItem>
            </VList>
          </div>
        </div>
      </div>
    </template>
    <!-- End Search box in desktop screen -->
  </div>
</template>

<style scoped lang="scss">
.search-box-container {
  display: flex;
  border: 1px solid colors-get(gray, 300);
  @include border-radius-default;
  box-shadow: none;
  background-color: colors-get(base, white);

  &.--focus {
    color: colors-get(gray, 900);
    border: 1px solid colors-get(primary, 300);
    box-shadow:
      0px 0px 0px 4px #f4ebff,
      0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  &.--disabled {
    background: colors-get(gray, 50);
  }

  .location-search-input {
    position: relative;

    :deep(.text-field-wrapper) {
      border-radius: rem(8) 0 0 rem(8);
      box-shadow: unset;
      border: 0;

      &.--focus {
        color: inherit !important;
        border: none !important;
        box-shadow: inherit !important;
      }
    }

    :deep(.text-field) {
      width: 100%;
      padding-left: spacings-get(2);
      padding-right: spacings-get(2);
    }

    .icon {
      margin-left: spacings-get(2);
      min-width: spacings-get(5);
    }

    .input-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .separator {
        padding: rem(10) 0;
        border-left: 1px solid colors-get(gray, 300);
      }

      :deep(.text-field-wrapper) {
        position: relative;

        &.--disabled {
          border-radius: 0 rem(8) rem(8) 0 !important;
        }

        svg {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          z-index: 2;
        }

        .text-field {
          padding-left: rem(36);
        }
      }
    }
  }

  .keyword-search-input {
    display: flex;
    align-items: center;

    &.--disabled {
      background: colors-get(gray, 50);
      border-radius: rem(8) 0 0 rem(8);
    }

    .icon {
      margin: 0 spacings-get(2) 0 spacings-get(4);
      min-width: spacings-get(5);
    }

    .text-field {
      width: 100%;
      padding: rem(10) 0;
      @include border-radius-default;
      @include fonts-get(regular, text-md);
      color: colors-get(gray, 900);
      background: colors-get(base, white);
      outline: none;

      &:disabled {
        background: colors-get(gray, 50);
        color: colors-get(gray, 500);

        &::placeholder {
          color: colors-get(gray, 400);
        }
      }

      &::placeholder {
        color: colors-get(gray, 400);
      }
    }
  }
}
</style>
export enum SEARCH_QUERY_NAME {
	KEYWORD = 'k',
	COORDINATES = 'o',
	LOCATION = 'l',
	COUNTRY_CODE = 'c',
	TIME_STAMP = 't',
	DATE_START = 'ds',
	DATE_END = 'de',
	EVENT_TYPE = 'e',
}

export enum CONNECT_STRIPE_STATUS_QUERY_NAME {
	CONNECT_STATUS = 'connect-status',
}